import React, { useState } from 'react'
import trhLogo from '../static/trhLogo.png'
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import { useNavigate, Link } from 'react-router-dom';
import { Circles } from 'react-loading-icons'
import Modal from '../modal/Modal'

// Firebase project configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_ID,
  appId: process.env.REACT_APP_APP_ID,
};

const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

export default function Dashboard(props) {
  let [num, setNum] = useState("");
  let [clients, setClients] = useState([]);

  let [loggedIn, setLoggedIn] = useState(true);
  let [isDisabled, setIsDisabled] = useState(false);
  let [loading, setLoading] = useState("none");

  const [modal, setModal] = useState(false);
  const [numModal, setNumModal] = useState("");
  const [blockButtonDisplay, setBlockButtonDisplay] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState({});

  // Log User Out
  const userLogOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/")
      })
      .catch((error) => {
        alert("Unable to sign out: " + error.message);
      })
  }


  const updateNum = (e) => {

    let num = e.target.value;
    num = num.replace(/[^0-9]/g, "")
    // console.log("num: ", num);
    
    // Adjust count of numbers
    if(num.length > 10) {
      num = num.slice(0,10)
    }
    
    e.target.value = num;

    // this.setState({num: num});
    setNum(num);
  }
  
  // Trigger on key down to trigger search
  const handleKeyDown = (e) => {
    // console.log("e: ", e);
    if(!isDisabled && e.key === "Enter") {
      numberSearch();
    }
  }

  // Search for client with the entered number
  const numberSearch = async () => {

    // Disable Search Button/Display Loading
    setIsDisabled(true);
    setLoading("block");
    console.log("SEARCHING FOR: ", num);
    // Grabe Number Entered and Validate
    // const num = this.state.num;
    if(num.length !== 10) {
      setIsDisabled(false);
      setLoading("none");
      alert("Phone number is not 10 digits.")
      return
    } 
    // Send request
    const data = {
      "num":num,
    };
  
    const response = await fetch(process.env.REACT_APP_API_URL + '/api/clients', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Method': 'POST',
      },
      body: JSON.stringify(data),
    });

  
    if (response.ok) {
      const responseData = await response.json();
      // console.log("responseData: ", responseData)
      // If no cases found let them know
      if(responseData.clients === null) {
        setClients([]);
        alert("NO CLIENTS FOUND WITH " + num + " PHONE NUMBER")
      } else {
        // Run through and format 
        for(let i=0;i<responseData.clients.length;i++) {
          let client = responseData.clients[i];
          // Format Sale Date
          client.sale_date = client.sale_date.split("T")[0];
          client.last_activity_date = client.last_activity_date.split("T")[0];
          
          // Format total Payments
          let total_paid = client.total_payments;
          // console.log("total_paid: ", total_paid)
          if(parseFloat(total_paid) >= 1000) {
            client.total_payments = "1,000 or more paid - Send to Customer Service";
          } else {
            client.total_payments = "Less than 1,000 paid - Send to Set. Officer";
          }
        }

        // Update State
        // this.setState({clients: responseData.clients});
        setClients(responseData.clients);
      }
    } else {
      // Handle error response
    }

    // Enable Search Button/Hide Loading
    setIsDisabled(false);
    setLoading("none");
  } 

  // Check logged in state
  onAuthStateChanged(auth, (user) => {
    // console.log("user: ", user);
    // Set the user to the state
    setLoggedInUser(user);
    if (user && user.emailVerified) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user

      // console.log("user.email: ", user.email);
      // Check if the block button should be shown
      if (props.managerList.includes(user.email.toLowerCase()) || props.adminList.includes(user.email.toLowerCase())) {
        setBlockButtonDisplay(true);
      }
      else {
        setBlockButtonDisplay(false);
      }
    } else {
      console.log("NOT LOGGED IN/VERIFIED");
      setLoggedIn(false);
      setBlockButtonDisplay(false);
    }
  });

  const navigate = useNavigate();
  if(!loggedIn)  {
    return navigate("/");
  }


  // Open block modal
  const toggleModal = () => {
    // console.log("modal state: ", modal);
    setModal(!modal)
  }

  const updateNumModal = (e) => {
    let num = e.target.value;
    num = num.replace(/[^0-9]/g, "")
    
    // Adjust count of numbers
    if(num.length > 10) {
      num = num.slice(0,10)
    }
    
    e.target.value = num;

    // this.setState({num: num});
    setNumModal(num);
  }
  
  // Trigger on key down to trigger search
  const handleKeyDownModal = (e) => {
    if(!isDisabled && e.key === "Enter") {
      addNumberToBlock();
    }
  }
  
  // This function will try to insert the number to blocked number list
  // But we need to make sure it's not already in the list
  const addNumberToBlock = async () => {
    // Check if the user is authorized to submit
    if (!props.managerList.includes(loggedInUser.email.toLowerCase()) && !props.adminList.includes(loggedInUser.email.toLowerCase()))  {
      alert("Permission denied. Please contact your manager to block phone numbers.");
      return;
    }

    setIsDisabled(true);
    setLoading("block");

    if (numModal.length !== 10) {
      setIsDisabled(false);
      setLoading("none");
      alert("Please ensure the phone number is 10 digits.");
      return;
    }
    console.log("Number to block: ", numModal);

    // Send number to backend
    const response = await fetch(process.env.REACT_APP_API_URL + '/api/block-number?phone=' + numModal, {
      method: 'GET',
    });

    // Print response in console.
    if (response.ok) {
      const responseData = await response.json();

      console.log("responseData: ", JSON.stringify(responseData))
      setIsDisabled(false);
      setLoading("none");
      alert("Phone number submitted successfully. Please allow a few minutes for it to be blocked.")
    } else {
      // Handle error response
      setIsDisabled(false);
      setLoading("none");
      alert("An error occurred while blocking the number. Please try again later.");
      console.log("response: ", JSON.stringify(response.json()))
      return;
    }
  }


  return (
    <div>
      <div className='topBar f justify-space-between'>
        <div>
          <img src={trhLogo} alt="Logo" height={50} />
        </div>
        <div>
          <>
          <Link to='/calculator'
          className='m-right-2'>
          Calculator
          </Link>
          </>
          {/* Block button */}
          { blockButtonDisplay && (
            <>
            <Link to='/reportPicker'
             className='m-right-2'>
              Reports
            </Link>
             <button 
             className='m-right-2'
             onClick={toggleModal}>
             Block Number
           </button>
            </>
          )}
         
          {/* Logout button */}
          <button className='m-right-2 redBg' onClick={() => userLogOut()}>Logout</button>

          <label className='m-right-2 blue-text bold-text underline'>Search Phone Number :</label>
          <input 
          onChange={(e) => updateNum(e)} 
          className='m-right-2' 
          onKeyDown={(e) => handleKeyDown(e)}
          type={"tel"} />
          {/* Search button */}
          <button id="searchButton" onClick={() => numberSearch()} disabled={isDisabled}>Search</button>
        </div>
      </div>
      {/* Main Content */}
      <div className='w-50 center m-top-3'>
        {/* Modal */}
        { modal && (
          <Modal
          toggleModal={toggleModal}
          updateNumModal={updateNumModal}
          handleKeyDownModal={handleKeyDownModal}
          addNumberToBlock={addNumberToBlock}
          addButtonDisabled={isDisabled}
          />
        )}
          
        <div className='text-center' style={{display: loading}}>
          <Circles stroke="#98ff98" />
        </div>
        {clients.map((c) => (
          <div key={c.case_number} className='clientBox'>
            <p>Case #: {c.case_number}</p>
            <p>Team: {c.team}</p>
            <p>Client Name: {c.name}</p>
            <p>Status: {c.status}</p>
            <p>Set. Officer: <span className='underline bold-text'>{c.settlement_officer}</span></p>
            <p>Sale Date: {c.sale_date}</p>
            <p>Total Paid: <span className='underline bold-text'>{c.total_payments}</span></p>
            <p>Cell: {c.cell}</p>
            <p>Home: {c.home}</p>
            <p>Work Phone: {c.work_phone}</p>
            <p>Last Activity: {c.last_activity}</p>
            <p>Last Activity Date: {c.last_activity_date}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
