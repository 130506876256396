import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import './LeadsByMarketingLine.css'
import "ag-grid-enterprise";
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';

import { adservRatio, percentageOf, percentageFormatter, currencyFormatter } from '../valueformatter';

export default function LeadsByMarketingLine(props) {
  const [gridOptions, setGridOptions] = useState({
    sideBar: true,
    rowGroupPanelShow: 'always',
    // groupDefaultExpanded: 1,
    columnHoverHighlight: true,
    // groupIncludeFooter: true,
    groupIncludeTotalFooter: true,
    suppressAggFuncInHeader: true,
    autoGroupColumnDef: {
      cellRendererParams: {
        footerValueGetter: params => {
          const isRootLevel = params.node.level === -1;
          console.log("isRootLevel: ", isRootLevel);
          if (isRootLevel) {
            return 'Grand Total';
          }
          return `Sub Total (${params.value})`;
        },
      },
      headerName: 'Vendor',
    },
    getRowStyle: params => {
      // Grand Total row formatting
      if (params.node.group === true && params.node.level === -1) {
        return { background: '#3F91B8', color: 'white', fontWeight: 'bold' };
        // #b5c4ca
        // #99bac9
      }
      // Subtotal row formatting
      else if (params.node.group === true) {
        return { background: '#b5c4ca', fontWeight: 'bold' };
      }
    },
    autoSizeStrategy: {
      type: 'fitCellContents'
    },
  });

  const gridRef = useRef(); // Optional - for accessing Grid's API

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    filter: true,
  }));

  const getGroupRowAgg = useCallback((params) => {
    const result = {
      form_count: 0,
      call_count: 0,
      case_count: 0,
      deal_count: 0,
      cases_calls: 0,
      deals_calls: 0,
      deals_cases: 0,
      vendor_spend: 0,
      initial: 0,
      total_payments: 0,
      ip_roi: 0,
      tp_roi: 0,
      avg_ip: 0,
      cpa: 0,
    }
    // Total up row values/make calcs
    params.nodes.forEach((node) => {
      const data = node.group ? node.aggData : node.data
      // Increment Form Count
      result.form_count += data.form_count;
      // Increment Call Count
      result.call_count += data.call_count;
      // Increment Cases Created
      result.case_count += data.case_count;
      // Increment Deals
      result.deal_count += data.deal_count;
      // Increment Vendor Spend
      result.vendor_spend += data.vendor_spend;
      // Increment IP
      result.initial += data.initial;
      // Increment TP
      result.total_payments += data.total_payments;
    });

    // Calc Cases/Calls
    if (result.call_count !== 0) {
      result.cases_calls = ((result.case_count / result.call_count) * 100);
    }

    // Calc Deals/Calls
    if (result.call_count !== 0) {
      result.deals_calls = ((result.deal_count / result.call_count) * 100);
    }

    // Calc Deals/Cases
    if (result.case_count !== 0) {
      result.deals_cases = ((result.deal_count / result.case_count) * 100);
    }

    // Calc IP ROI
    if (result.vendor_spend !== 0) {
      result.ip_roi = parseFloat((result.initial / result.vendor_spend).toFixed(2));
    }

    // Calc TP ROI
    if (result.vendor_spend !== 0) {
      result.tp_roi = parseFloat((result.total_payments / result.vendor_spend).toFixed(2));
    }

    // Calc Avg IP 
    if (result.deal_count !== 0 ) {
      result.avg_ip = (result.initial / result.deal_count);
    }

    // Calc CPA
    if (result.deal_count !== 0 && result.vendor_spend !== 0) {
      result.cpa = parseFloat((result.vendor_spend / result.deal_count).toFixed(2));
    }

    return result;
  });

  console.log("props: ", props);
  /*
    Row Object Example
    {
      "vendor":"Barrington Media, LLC",
      "description":"Radio-EN-BMG",
      "to_addr":"5014433689",
      "call_count":"243",
      "case_count":"10",
      "deal_count":"12",
      "calls_cases":"4.12",
      "deals_calls":"4.94",
      "deals_cases":"120",
      "initial":"11870",
      "vendor_spend":"16131",
      "ip_roi":"0.74",
    }
  */

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([
    { field: "vendor", headerName: "Vendor", sort: "asc", enableRowGroup: true, rowGroup: true, hide: true },
    { field: "description", headerName: "Marketing Line", },
    // { field: "to_addr", headerName: "Phone", cellStyle: { textAlign: 'right' } },
    { field: "phone_number", headerName: "Phone", cellStyle: { textAlign: 'right' } },
    { field: "form_count", headerName: "Forms", cellStyle: { textAlign: 'right' }, aggFunc: 'sum', cellRenderer: "agAnimateShowChangeCellRenderer", },
    { field: "call_count", headerName: "Calls", cellStyle: { textAlign: 'right' }, aggFunc: 'sum', cellRenderer: "agAnimateShowChangeCellRenderer", },
    { field: "case_count", headerName: "Cases", cellStyle: { textAlign: 'right' }, aggFunc: 'sum' },
    { field: "deal_count", headerName: "Deals", cellStyle: { textAlign: 'right' }, aggFunc: 'sum' },
    {
      field: "cases_calls", headerName: "Cases/Calls", cellStyle: { textAlign: 'right' },
      valueGetter: params => percentageOf(params.data.case_count, params.data.call_count),
      valueFormatter: params => percentageFormatter(params.value),
    },
    {
      field: "deals_calls", headerName: "Deals/Calls", cellStyle: { textAlign: 'right' },
      valueGetter: params => percentageOf(params.data.deal_count, params.data.call_count),
      valueFormatter: params => percentageFormatter(params.value),
    },
    {
      field: "deals_cases", headerName: "Deals/Cases", cellStyle: { textAlign: 'right' },
      valueGetter: params => percentageOf(params.data.deal_count, params.data.case_count),
      valueFormatter: params => percentageFormatter(params.value),
    },
    { field: "initial", headerName: "IP", sort: "desc", cellStyle: { textAlign: 'right' }, aggFunc: 'sum', valueFormatter: params => currencyFormatter(params.value, "$"), },
    { field: "total_payments", headerName: "TP", sort: "desc", cellStyle: { textAlign: 'right' }, aggFunc: 'sum', valueFormatter: params => currencyFormatter(params.value, "$"), },
    { field: "vendor_spend", headerName: "Vendor Spend", cellStyle: { textAlign: 'right' }, aggFunc: 'sum', valueFormatter: params => currencyFormatter(params.value, "$"), },
    {
      field: "ip_roi", headerName: "IP ROI", cellStyle: { textAlign: 'right' },
      valueGetter: params => adservRatio(params.data.initial, params.data.vendor_spend)
    },
    {
      field: "tp_roi", headerName: "TP ROI", cellStyle: { textAlign: 'right' },
      valueGetter: params => adservRatio(params.data.total_payments, params.data.vendor_spend)
    },
    {
      field: "avg_ip", headerName: "IP AVG", cellStyle: { textAlign: 'right' },
      valueFormatter: params => currencyFormatter(params.value, "$"),
      valueGetter: params => adservRatio(params.data.initial, params.data.deal_count) // Need to set the valueGetter to calc each row
    },
    {
      field: "cpa", headerName: "CPA", cellStyle: { textAlign: 'right' },
      valueFormatter: params => currencyFormatter(params.value, "$"), 
    },
  ]);

  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState([]);

  // Preserving Filter Model
  const [filterModel, setFilterModel] = useState(null);  // Save current filters

  // Loading state to track when data is being fetched
  const [loading, setLoading] = useState(true);

  // Fetch data based on date range
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true before fetching
      if (gridRef.current) {
        // Save current filter state
        const currentFilterModel = gridRef.current.api.getFilterModel();
        setFilterModel(currentFilterModel);
      }

      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      const reqUrl = `${process.env.REACT_APP_API_URL}/api/reports?report=${props.reportName}&startDate=${props.startDate}&endDate=${props.endDate}`;

      try {
        const result = await fetch(reqUrl, requestOptions);
        const gridData = await result.json();
        setRowData(gridData.data);
      } catch (error) {
        console.error("ERROR: " + error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchData();
  }, [props.reportName, props.startDate, props.endDate]);

  // After new data is set, reapply the filter model
  useEffect(() => {
    if (gridRef.current && filterModel) {
      gridRef.current.api.setFilterModel(filterModel);
    }
  }, [rowData]);

  // If rows have not yet been returned
  // if (loading) {
  //   return (
  //     <div>
  //       <h1 className='color2-font'>DATA LOADING</h1>
  //     </div>
  //   )
  // }

  // Subtotals/Grand Total row formatting
  const groupColDef = {
    cellRendererParams: {
      footerValueGetter: params => {
        const isRootLevel = params.node.level === -1;
        if (isRootLevel) {
          return 'Grand Total';
        }
        return `Sub Total (${params.value})`;
      },
    },
    headerName: 'Vendor',
  }

  // possible options: 'never', 'always', 'onlyWhenGrouping'
  // const rowGroupPanelShow = 'always';

  // This will autosize these columns since I think the reason they're not be resized with the gridoption above
  // is b/c we're calculating them and creating here instead of the data coming straght from the API
  const onGridReady = (params) => {
    params.api.sizeColumnsToFit(); // Resize columns to fit the grid's width
    const columnIds = ['ip_roi', 'tp_roi', 'avg_ip', 'cpa'];
    params.api.autoSizeColumns(columnIds); // Auto-size the specific columns
  };

  return (
    // wrapping container with theme & size
    <div
      className="ag-theme-quartz h-60 m-top-3 greyBg" // applying the grid theme
    // style={{ height: 100% }} // the grid will fill the size of the parent container 
    >
      <div id="summaryBox">
        <p id="summaryText">
          <strong>Leads by Marketing Line</strong><br />
          This view will show data for inbound calls for each marketing line grouped by vendor within the filtered date range.
          <br />
          <ul>
            <li>Calls are filtered by duration greater than 4 seconds</li>
          </ul>
        </p>
      </div>
      <div style={{ position: 'relative', height: '100%' }}> {/* New wrapper for AG Grid */}
        <AgGridReact
          enableCharts={true}
          enableRangeSelection={true}
          onGridReady={onGridReady}
          rowData={rowData}
          columnDefs={colDefs}
          getGroupRowAgg={getGroupRowAgg}
          gridOptions={gridOptions}
          defaultColDef={defaultColDef}
          modules={[ServerSideRowModelModule, RowGroupingModule]}
        />
        {loading && (
            <div className="loading-overlay">
            <div className="spinner"></div>
            </div>
        )}
      </div>
    </div>
  )
}